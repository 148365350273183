import {useMemo} from 'react'
import {InventoryAsset} from '../../apps/dashboard/components/InventoryAsset'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useGetRevenueSharingCard} from '../core/_query'

function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
}

const RevenueSharingCard = () => {
  const {state} = useQueryRequest()
  const {data: cardData} = useGetRevenueSharingCard(state)

  const data = useMemo(() => {
    return cardData?.response?.data
  }, [cardData])

  return (
    <>
      <div className='col'>
        <InventoryAsset
          className='card-xl mb-xl-8'
          // className='card-xl-stretch mb-xl-8'
          svgIcon='chart-simple'
          color='white'
          iconColor='primary'
          title={data?.revenue ? 'Rp ' + numberWithCommas(data?.revenue) : 'Rp. 0'}
          description='Revenue'
        />
      </div>
      <div className='col'>
        <InventoryAsset
          className='card-xl mb-xl-8'
          // className='card-xl-stretch mb-xl-8'
          svgIcon='bank'
          color='white'
          iconColor='primary'
          title={data?.tax ? 'Rp ' + numberWithCommas(data?.tax) : 'Rp. 0'}
          description='Taxes'
        />
      </div>
      <div className='col'>
        <InventoryAsset
          className='card-xl mb-xl-8'
          // className='card-xl-stretch mb-xl-8'
          svgIcon='briefcase'
          color='white'
          iconColor='primary'
          title={data?.service ? 'Rp ' + numberWithCommas(data?.service) : 'Rp. 0'}
          description='Services'
        />
      </div>
      <div className='col'>
        <InventoryAsset
          className='card-xl mb-xl-8'
          // className='card-xl-stretch mb-xl-8'
          svgIcon='chart-pie-simple'
          color='white'
          iconColor='primary'
          title={
            data?.revenue_after_tax_service
              ? 'Rp ' + numberWithCommas(data?.revenue_after_tax_service)
              : 'Rp. 0'
          }
          description='Revenue After Taxes Services'
        />
      </div>
    </>
  )
}

export {RevenueSharingCard}
