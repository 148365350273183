import {BarChart} from '@/_metronic/partials/content/chart/BarChart'
import {useMemo} from 'react'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useGetRevenueServiceSharingWit} from '../core/_query'

const RevenueSharingWit = () => {
  const {state} = useQueryRequest()
  const {data: revenueWib, isLoading} = useGetRevenueServiceSharingWit(state)

  const arrData = useMemo(() => {
    return revenueWib?.response?.data || []
  }, [revenueWib])

  const labels = useMemo(() => {
    return arrData?.map((x) => x.periode || '')
  }, [arrData])

  const revenueOutlet = useMemo(() => {
    return arrData?.map((x) => x.service_share_outlet || 0)
  }, [arrData])

  const revenuePartner = useMemo(() => {
    return arrData?.map((x) => x.service_share_partner || 0)
  }, [arrData])

  return (
    <BarChart
      className='area-wib mt-10 shadow-wit'
      color='primary'
      title='Revenue & Service Sharing'
      subtitle='Area WIT'
      height='400px'
      series={[
        {
          name: 'Outlet',
          data: revenueOutlet,
        },
        {
          name: 'Hotel',
          data: revenuePartner,
        },
      ]}
      categories={labels}
      total={0}
      isTotal={false}
      data={arrData?.map((x) => ({
        month: x.periode,
        revenue_share_outlet: x.revenue_share_outlet,
        revenue_share_partner: x.revenue_share_partner,
        service_share_outlet: x.service_share_outlet,
        service_share_partner: x.service_share_partner,
      }))}
      headers={[
        'Periode',
        'Revenue Sharing Outlet',
        'Revenue Sharing Partner',
        'Service Sharing Outlet',
        'Service Sharing Partner',
      ]}
      formatCurrency={[
        'revenue_share_outlet',
        'revenue_share_partner',
        'service_share_outlet',
        'service_share_partner',
      ]}
      isLoading={isLoading}
    />
  )
}

export {RevenueSharingWit}
