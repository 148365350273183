import {axios} from '@/_metronic/helpers/network/Axios'
import {AxiosResponse} from 'axios'
import {
  FilterTop,
  Revenue,
  RevenueStreamAreaQueryResponse,
  RevenueStreamCityQueryResponse,
  RevenueStreamOutletQueryResponse,
  SummaryTrend,
  Top5Therapist,
  Treatment,
} from './_models'
import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'

const REPORT_API_URL = '/report-services'
const GET_REVENUE_STREAM_OUTLET_LIST = '/dashboard/revenue-stream/outlet'
const GET_REVENUE_STREAM_CITY_LIST = '/dashboard/revenue-stream/city'
const GET_REVENUE_STREAM_AREA_LIST = '/dashboard/revenue-stream/area'
const GET_REPORT_TREND_ANALYSIS_LIST = '/dashboard/trend-analysis'

const getRevenueTopOutlet = (body: FilterTop): Promise<RevenueStreamOutletQueryResponse> => {
  return axios
    .post(`${REPORT_API_URL}${GET_REVENUE_STREAM_OUTLET_LIST}`, body)
    .then((d: AxiosResponse<RevenueStreamOutletQueryResponse>) => d.data)
}
const getRevenueTopCity = (body: FilterTop): Promise<RevenueStreamCityQueryResponse> => {
  return axios
    .post(`${REPORT_API_URL}${GET_REVENUE_STREAM_CITY_LIST}`, body)
    .then((d: AxiosResponse<RevenueStreamCityQueryResponse>) => d.data)
}
const getRevenueTopArea = (body: FilterTop): Promise<RevenueStreamAreaQueryResponse> => {
  return axios
    .post(`${REPORT_API_URL}${GET_REVENUE_STREAM_AREA_LIST}`, body)
    .then((d: AxiosResponse<RevenueStreamAreaQueryResponse>) => d.data)
}

const getRevenueTrendAnalysis = (body: any) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_TREND_ANALYSIS_LIST}/revenue`, body)
    .then((d: AxiosResponse<BaseResponse<Revenue[]>>) => d.data)

const getTreatmentTrendAnalysis = (body: any) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_TREND_ANALYSIS_LIST}/revenue-treatments`, body)
    .then((d: AxiosResponse<BaseResponse<Treatment[]>>) => d.data)

const getSummaryTrend = (body: any) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_TREND_ANALYSIS_LIST}/summary-trend`, body)
    .then((d: AxiosResponse<BaseResponse<SummaryTrend>>) => d.data)

const getTop5Therapist = (body: any) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_TREND_ANALYSIS_LIST}/top-5-therapist`, body)
    .then((d: AxiosResponse<BaseResponse<Top5Therapist[]>>) => d.data)

export {
  getRevenueTopArea,
  getRevenueTopCity,
  getRevenueTopOutlet,
  getRevenueTrendAnalysis,
  getTreatmentTrendAnalysis,
  getSummaryTrend,
  getTop5Therapist,
}
