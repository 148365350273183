import {memo} from 'react'
import {TopEmployees} from './components/TopEmployee'
import {TopTreatment} from './components/TopTreatment'
import {TrendAnalysisCategoryChart} from './components/chart/TrendAnalysisCategoryChart'
import {TrendAnalysisRevenueBar} from './components/chart/TrendAnalysisRevenueBarChart'
import {TrendAnalysisRevenueTreatmentChart} from './components/chart/TrendAnalysisRevenueTreatmentChart'
import {TrendAnalysisTransactionBar} from './components/chart/TrendAnalysisTransactionBarChart'
import {TrendAnalysisTreatmentChart} from './components/chart/TrendAnalysisTreatmentChart'
import {FilterReportTrendAnalysisModal} from './components/filter/FilterReportTrendAnalysisModal'
import {useFilterReportTrendAnalysis} from './core/FilterReportTrendAnalysisProvider'

const MemoizeTrendAnalysisRevenue = memo(TrendAnalysisRevenueBar)
const MemoizeTrendAnalysisTransaction = memo(TrendAnalysisTransactionBar)
const MemoizeTrendAnalysisTreatment = memo(TrendAnalysisRevenueTreatmentChart)

const MemoizeTrendAnalysisCategory = memo(TrendAnalysisCategoryChart)
const MemoizeTrendAnalysisTrendTreatment = memo(TrendAnalysisTreatmentChart)

const TrendAnalysisOverview = () => {
  const {closeModal, showFilterReportTrendAnalysisModal} = useFilterReportTrendAnalysis()

  return (
    <>
      <div className='mt-8 mb-7'>
        <div className='col-md-12 mb-12'>
          <MemoizeTrendAnalysisRevenue />
        </div>
        <div className='col-md-12 mb-12'>
          <MemoizeTrendAnalysisTransaction />
        </div>
        <div className='col-md-12 mb-12'>
          <MemoizeTrendAnalysisTreatment />
        </div>
        <div className='row mb-12' style={{height: '559.64px'}}>
          <div className='col-md-6'>
            <TopTreatment className='shadow-wit-2 mb-10 h-100' />
          </div>
          <div className='col-md-6'>
            <TopEmployees className='shadow-wit-2 h-100' />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <MemoizeTrendAnalysisCategory />
          </div>
          <div className='col-md-12'>
            <MemoizeTrendAnalysisTrendTreatment />
          </div>
        </div>
        {/* <div className='row mt-15'>
          <div className='col-md-4'>
            <CategoryAnalysisPieChart className='shadow-wit' />
          </div>
          <div className='col-md-8'>
            <TopTreatment className='shadow-wit-2' data={data?.top_five_product} />
          </div>
        </div> */}
        {/* <div className='row mt-15'>
          <div className='col-md-4'>
            <TreatmentAnalysisPieChart className='shadow-wit' />
          </div>
          <div className='col-md-8'>
            <TopEmployees className='shadow-wit-2' data={data?.top_five_therapist} />
          </div>
        </div> */}
      </div>
      <FilterReportTrendAnalysisModal
        show={showFilterReportTrendAnalysisModal}
        closeModal={() => closeModal()}
      />
    </>
  )
}

export {TrendAnalysisOverview}
