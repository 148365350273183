import {useQuery} from 'react-query'
import {FilterReportRevenueSharing} from './_models'
import {
  getRevenueCard,
  getRevenueServiceSharingHistory,
  getRevenueServiceSharingHistoryWib,
  getRevenueServiceSharingHistoryWit,
  getRevenueServiceSharingHistoryWita,
  getRevenueSharingCard,
  getRevenueSharingTopCity,
  getRevenueSharingTopOutlet,
} from './_requests'

export const useGetRevenueSharingCard = (body: FilterReportRevenueSharing) =>
  useQuery({
    queryFn: () => getRevenueCard(body),
    queryKey: `revenue-sharing-card-${JSON.stringify(body)}`,
    refetchOnWindowFocus: false,
    enabled: Boolean(body),
  })

export const useGetRevenueSharingCardSharing = (body: FilterReportRevenueSharing) =>
  useQuery({
    queryFn: () => getRevenueSharingCard(body),
    queryKey: `revenue-sharing-card-sharing-${JSON.stringify(body)}`,
    refetchOnWindowFocus: false,
    enabled: Boolean(body),
  })

export const useGetRevenueServiceSharingHistory = (body: FilterReportRevenueSharing) =>
  useQuery({
    queryFn: () => getRevenueServiceSharingHistory(body),
    queryKey: `revenue-service-sharing-history-${JSON.stringify(body)}`,
    refetchOnWindowFocus: false,
    enabled: Boolean(body),
  })

export const useGetRevenueServiceSharingWib = (body: FilterReportRevenueSharing) =>
  useQuery({
    queryFn: () => getRevenueServiceSharingHistoryWib(body),
    queryKey: `revenue-service-sharing-wib-${JSON.stringify(body)}`,
    refetchOnWindowFocus: false,
    enabled: Boolean(body),
  })

export const useGetRevenueServiceSharingWita = (body: FilterReportRevenueSharing) =>
  useQuery({
    queryFn: () => getRevenueServiceSharingHistoryWita(body),
    queryKey: `revenue-service-sharing-wita-${JSON.stringify(body)}`,
    refetchOnWindowFocus: false,
    enabled: Boolean(body),
  })

export const useGetRevenueServiceSharingWit = (body: FilterReportRevenueSharing) =>
  useQuery({
    queryFn: () => getRevenueServiceSharingHistoryWit(body),
    queryKey: `revenue-service-sharing-wit-${JSON.stringify(body)}`,
    refetchOnWindowFocus: false,
    enabled: Boolean(body),
  })

export const useGetRevenueTopOutlet = (body: FilterReportRevenueSharing) =>
  useQuery({
    queryFn: () => getRevenueSharingTopOutlet(body),
    queryKey: `revenue-sharing-top-outlet-${JSON.stringify(body)}`,
    refetchOnWindowFocus: false,
    enabled: Boolean(body),
  })

export const useGetRevenueTopCity = (body: FilterReportRevenueSharing) =>
  useQuery({
    queryFn: () => getRevenueSharingTopCity(body),
    queryKey: `revenue-sharing-top-city-${JSON.stringify(body)}`,
    refetchOnWindowFocus: false,
    enabled: Boolean(body),
  })
