import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'

export interface FilterReportTrendAnalysis {
  set_sub_brand_id?: boolean
  sub_brand_id?: string[]
  set_brand_id?: boolean
  brand_id?: string[]
  set_area?: boolean
  area?: string[]
  set_region_id?: boolean
  region_id?: string[]
  set_city_id?: boolean
  city_id?: string[]
  set_outlet_id?: boolean
  outlet_id?: string[]
  month?: string[]
  year?: string | null
}

export interface ReportTrendAnalysis {
  summary_trend?: SummaryTrendClass
  trend_product?: TrendProduct[]
  trend_transaction?: TrendTransaction[]
  trend_category?: TrendCategory[]
  trend_treatment?: TrendTreatment[]
  top_five_product?: TopFiveProduct[]
  top_five_therapist?: TopFiveTherapist[]
}

export interface SummaryTrendClass {
  product?: number
  transaction?: number
  sales?: number
}

export interface TrendTreatment {
  total_qty?: number
  total_sales?: number
  product_guid?: string
  product_name?: string
}

export interface TopFiveProduct {
  total_qty?: number
  total_sales?: number
  product_guid?: string
  product_name?: string
}

export interface TopFiveTherapist {
  total_qtys?: number
  outlet_guid?: string
  outlet_name?: string
  total_sales?: number
  employee_guid?: string
  employee_name?: string
  employee_picture?: string
}

export interface TrendCategory {
  total_qty?: number
  category_id?: string
  total_sales?: number
  category_name?: string | null
}

export interface TrendProduct {
  month?: string
  total_qty?: number
}

export interface TrendTransaction {
  month?: string
  total_sales?: number
  total_transaction?: number
}

export type Top5Product = {
  total_qty?: number
  total_sales?: number
  product_guid?: string
  product_name?: string
}

export type ReportTrendAnalysisQueryResponse = BaseResponse<ReportTrendAnalysis>

const currentDate = new Date()
export const initialFilter: FilterReportTrendAnalysis = {
  set_sub_brand_id: false,
  sub_brand_id: [],
  set_brand_id: false,
  brand_id: [],
  set_area: false,
  area: [],
  set_region_id: false,
  region_id: [],
  set_city_id: false,
  city_id: [],
  set_outlet_id: false,
  outlet_id: [],
  year: currentDate.getFullYear().toString(),
  month: [],
}
