import {useMemo} from 'react'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useGetTrendCategory} from '../../core/_query'
import {CategoryTreatmentBarChart} from './CategoryTreatmentBarChart'

const TrendAnalysisCategoryChart = () => {
  const {state} = useQueryRequest()
  const {data: trendCategories} = useGetTrendCategory(state)

  const sorted = useMemo(() => {
    return trendCategories?.response?.data?.sort((a, b) => {
      return (a?.category_name || '').localeCompare(b?.category_name || '')
    })
  }, [trendCategories])

  const arrayOfSeriesscategory = useMemo(() => {
    return sorted?.map((x) => x?.total_sales || 0)
  }, [trendCategories])

  const arrayOfLabelscategory = useMemo(() => {
    return sorted?.map((x) => x.category_name)
  }, [trendCategories])

  return (
    <CategoryTreatmentBarChart
      className='shadow-wit mb-10'
      color='info'
      title='Category'
      isCurrency={true}
      series={[
        {
          name: 'Category',
          data: arrayOfSeriesscategory,
        },
      ]}
      categories={arrayOfLabelscategory}
    />
  )
}

export {TrendAnalysisCategoryChart}
