import {BarChartOnly} from '@/_metronic/partials/content/chart/BarChartOnly'
import {
  useGetRevenue,
  useGetSummaryTrend,
  useGetTreatment,
} from '@/app/modules/apps/dashboard/core/_query'
import {memo, useMemo} from 'react'
import {FormattedNumber} from 'react-intl'
import {useQueryRequest} from '../../core/QueryRequestProvider'

const MemoizedBarChart = memo(BarChartOnly)

const TrendAnalysisRevenueTreatmentChart = () => {
  const {state} = useQueryRequest()
  const {data: revenueData, isLoading} = useGetTreatment(state)

  const revenue = useMemo(() => {
    return revenueData?.response?.data
  }, [revenueData])

  return (
    <>
      <div className='card area-wib shadow-wit'>
        <RevenueHeader />
        <MemoizedBarChart
          className='area-wib shadow-wit'
          color='primary'
          title='Treatment'
          data={revenue?.map((x) => ({
            month: x.month,
            total_qty: x.total_qty,
          }))}
          headers={['Bulan', 'Total Treatment']}
          formatThousand={['total_qty']}
          series={[
            {
              name: 'Treatment',
              data: revenue?.map((item) => item.total_qty),
            },
          ]}
          categories={revenue?.map((item) =>
            item.month
              ?.split(`${state.year || new Date().getFullYear()}`)[0]
              .substring(0, 3)
              .trim()
          )}
          //   total={data?.summary_trend?.sales}
          layout='horizontal'
          isLoading={isLoading}
        />
      </div>
    </>
  )
}

const RevenueHeader = () => {
  const {state} = useQueryRequest()
  const {data: summaryTrendData, isLoading: summaryTrendLoading} = useGetSummaryTrend(state)

  const summaryTrend = useMemo(() => {
    return summaryTrendData?.response?.data
  }, [summaryTrendData])

  return (
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>Treatment</span>
      </h3>
      <div className='card-toolbar'>
        {summaryTrendLoading ? (
          <div className='h-25px w-70px placeholder-glow'>
            <span className='w-100 h-100 placeholder' />
          </div>
        ) : (
          <span className={`card-title fw-bold fs-1 text-primary`}>
            <FormattedNumber value={summaryTrend?.summary_trend?.product || 0} />
          </span>
        )}
      </div>
    </div>
  )
}

export {TrendAnalysisRevenueTreatmentChart}
