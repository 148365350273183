import {axios} from '@/_metronic/helpers/network/Axios'
import {AxiosResponse} from 'axios'
import {
  Card,
  CardShare,
  FilterReportRevenueSharing,
  ReportRevenueSharingQueryResponse,
  RevenueTopCity,
  RevenueTopOutlet,
} from './_models'
import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'

const REPORT_API_URL = '/report-services'
const GET_REPORT_REVENUE_SAHRING_LIST = '/dashboard/revenue-sharing'

/**
 * @deprecated
 */
const getReportRevenueSharing = (
  body: FilterReportRevenueSharing
): Promise<ReportRevenueSharingQueryResponse> => {
  return axios
    .post(`${REPORT_API_URL}${GET_REPORT_REVENUE_SAHRING_LIST}`, body)
    .then((d: AxiosResponse<ReportRevenueSharingQueryResponse>) => d.data)
}

const getRevenueCard = (body: FilterReportRevenueSharing) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_REVENUE_SAHRING_LIST}/card`, body)
    .then((d: AxiosResponse<BaseResponse<Card>>) => d.data)

const getRevenueSharingCard = (body: FilterReportRevenueSharing) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_REVENUE_SAHRING_LIST}/card-share`, body)
    .then((d: AxiosResponse<BaseResponse<CardShare>>) => d.data)

const getRevenueServiceSharingHistory = (body: FilterReportRevenueSharing) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_REVENUE_SAHRING_LIST}/revenue-service-history`, body)
    .then((d: AxiosResponse<BaseResponse<CardShare[]>>) => d.data)

const getRevenueServiceSharingHistoryWib = (body: FilterReportRevenueSharing) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_REVENUE_SAHRING_LIST}/revenue-wib`, body)
    .then((d: AxiosResponse<BaseResponse<CardShare[]>>) => d.data)

const getRevenueServiceSharingHistoryWita = (body: FilterReportRevenueSharing) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_REVENUE_SAHRING_LIST}/revenue-wita`, body)
    .then((d: AxiosResponse<BaseResponse<CardShare[]>>) => d.data)

const getRevenueServiceSharingHistoryWit = (body: FilterReportRevenueSharing) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_REVENUE_SAHRING_LIST}/revenue-wit`, body)
    .then((d: AxiosResponse<BaseResponse<CardShare[]>>) => d.data)

const getRevenueSharingTopOutlet = (body: FilterReportRevenueSharing) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_REVENUE_SAHRING_LIST}/revenue-top-outlet`, body)
    .then((d: AxiosResponse<BaseResponse<RevenueTopOutlet[]>>) => d.data)

const getRevenueSharingTopCity = (body: FilterReportRevenueSharing) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_REVENUE_SAHRING_LIST}/revenue-top-city`, body)
    .then((d: AxiosResponse<BaseResponse<RevenueTopCity[]>>) => d.data)

export {
  getReportRevenueSharing,
  getRevenueCard,
  getRevenueSharingCard,
  getRevenueServiceSharingHistory,
  getRevenueServiceSharingHistoryWib,
  getRevenueServiceSharingHistoryWita,
  getRevenueServiceSharingHistoryWit,
  getRevenueSharingTopOutlet,
  getRevenueSharingTopCity,
}
