/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo} from 'react'
import {FormattedNumber, IntlProvider} from 'react-intl'
import {TopFiveProduct} from '../core/_models'
import {useGetTop5Product} from '../core/_query'
import {useQueryRequest} from '../core/QueryRequestProvider'

type Props = {
  className: string
}

const TopTreatment: React.FC<Props> = ({className}) => {
  const {state} = useQueryRequest()
  const {data: topTreatmentData} = useGetTop5Product(state)

  const data = useMemo(() => {
    return topTreatmentData?.response?.data || []
  }, [topTreatmentData])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3'>Top 5 Treatment</span>
        </h3>
        {/* <div className='card-toolbar' data-kt-buttons='true'>
            <a
              className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
              id='kt_charts_widget_2_year_btn'
            >
              Quantity
            </a>

            <a
              className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
              id='kt_charts_widget_2_month_btn'
            >
              Sales
            </a>
          </div> */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-30px'>No</th>
                <th className='min-w-200px'>Product</th>
                <th className='min-w-100px'>Quantity</th>
                <th className='min-w-150px'>Revenue</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data !== undefined ? (
                data?.map((treatment, index) => (
                  <tr key={index}>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {index + 1}
                      </a>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {treatment.product_name}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        <IntlProvider locale='id'>
                          <FormattedNumber value={treatment.total_qty || 0} />
                        </IntlProvider>
                      </a>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2 text-dark me-2 fs-7 fw-semibold'>
                          Rp.{' '}
                          <IntlProvider locale='id'>
                            <FormattedNumber value={treatment.total_sales || 0} />
                          </IntlProvider>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TopTreatment}
