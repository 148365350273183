import {useMemo} from 'react'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useGetTrendTreatment} from '../../core/_query'
import {CategoryTreatmentBarChart} from './CategoryTreatmentBarChart'

const TrendAnalysisTreatmentChart = () => {
  const {state} = useQueryRequest()
  const {data: trendTreatment} = useGetTrendTreatment(state)

  const sorted = useMemo(() => {
    return trendTreatment?.response?.data?.sort((a, b) => {
      return (a?.product_name || '').localeCompare(b?.product_name || '')
    })
  }, [trendTreatment])

  const arrayOfSeriesTrendTreatment = useMemo(() => {
    return sorted?.map((x) => x?.total_sales || 0)
  }, [trendTreatment])

  const arrayOfLabelsTrendTreatment = useMemo(() => {
    return sorted?.map((x) => x.product_name)
  }, [trendTreatment])

  const heightTreatment = useMemo(() => {
    return sorted?.reduce((prev, _) => prev + 54.54, 0)
  }, [trendTreatment])

  return (
    <CategoryTreatmentBarChart
      className='shadow-wit mb-10'
      color='info'
      title='Treatment'
      height={heightTreatment}
      isCurrency={true}
      series={[
        {
          name: 'Treatment',
          data: arrayOfSeriesTrendTreatment,
        },
      ]}
      categories={arrayOfLabelsTrendTreatment}
    />
  )
}

export {TrendAnalysisTreatmentChart}
