import {BarChart} from '@/_metronic/partials/content/chart/BarChart'
import {memo, useEffect, useState} from 'react'
import {InventoryAsset} from '../apps/dashboard/components/InventoryAsset'
import TopCities from './components/TopCities'
import TopOutlets from './components/TopOutlets'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from './core/QueryResponseProvider'
import {RevenueSharingCard} from './components/RevenueSharingCard'
import {RevenueSharingCardSharing} from './components/RevenueSharingCardSharing'
import {RevenueServiceSharingHistory} from './components/RevenueServiceSharingHistory'
import {RevenueSharingWib} from './components/RevenueSharingWib'
import {RevenueSharingWita} from './components/RevenueSharingWita'
import {RevenueSharingWit} from './components/RevenueSharingWit'

const MemoizedRevenueSharingCard = memo(RevenueSharingCard)
const MemoizedRevenueSharingCardSharing = memo(RevenueSharingCardSharing)
const MemoizeRevenueSharingHistory = memo(RevenueServiceSharingHistory)
const MemoizeRevenueWib = memo(RevenueSharingWib)
const MemoizeRevenueWita = memo(RevenueSharingWita)
const MemoizeRevenueWit = memo(RevenueSharingWit)

const Overview = () => {
  const isLoading = useQueryResponseLoading()
  const response = useQueryResponse()
  const data = useQueryResponseData()
  //Revenue Sharing
  const [arrayOfSeriesRevenueSharingRevenueOutlet, setArrayOfSeriesRevenueSharingRevenueOutlet] =
    useState<number[]>([])
  const [arrayOfSeriesRevenueSharingRevenuePartner, setArrayOfSeriesRevenueSharingRevenuePartner] =
    useState<number[]>([])
  const [arrayOfSeriesRevenueSharingServiceOutlet, setArrayOfSeriesRevenueSharingServiceOutlet] =
    useState<number[]>([])
  const [arrayOfSeriesRevenueSharingServicePartner, setArrayOfSeriesRevenueSharingServicePartner] =
    useState<number[]>([])
  const [arrayOfLabelsRevenueSharing, setArrayOfLabelsRevenueSharing] = useState<string[]>([])

  // WIB
  const [arrayOfSeriesWibServiceOutlet, setArrayOfSeriesWibServiceOutlet] = useState<number[]>([])
  const [arrayOfSeriesWibServicePartner, setArrayOfSeriesWibServicePartner] = useState<number[]>([])
  const [arrayOfLabelsWib, setArrayOfLabelsWib] = useState<string[]>([])

  // WITA
  const [arrayOfSeriesWitaServiceOutlet, setArrayOfSeriesWitaServiceOutlet] = useState<number[]>([])
  const [arrayOfSeriesWitaServicePartner, setArrayOfSeriesWitaServicePartner] = useState<number[]>(
    []
  )
  const [arrayOfLabelsWita, setArrayOfLabelsWita] = useState<string[]>([])

  // WIT
  const [arrayOfSeriesWitServiceOutlet, setArrayOfSeriesWitServiceOutlet] = useState<number[]>([])
  const [arrayOfSeriesWitServicePartner, setArrayOfSeriesWitServicePartner] = useState<number[]>([])
  const [arrayOfLabelsWit, setArrayOfLabelsWit] = useState<string[]>([])

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
  }

  useEffect(() => {
    if (data) {
      //Revenue Sharing
      setArrayOfSeriesRevenueSharingRevenueOutlet([])
      setArrayOfSeriesRevenueSharingRevenuePartner([])
      setArrayOfSeriesRevenueSharingServiceOutlet([])
      setArrayOfSeriesRevenueSharingServicePartner([])
      setArrayOfLabelsRevenueSharing([])
      if (data?.revenue_sharing) {
        const arrayOfLabelsRevenueSharingString = data?.revenue_sharing?.map((obj) =>
          obj.periode ? obj.periode : ''
        )
        setArrayOfLabelsRevenueSharing(arrayOfLabelsRevenueSharingString)
        const arrayOfSeriesRevenueSharingRevenueOutletString = data?.revenue_sharing?.map((obj) =>
          obj.revenue_share_outlet ? obj.revenue_share_outlet : 0
        )
        setArrayOfSeriesRevenueSharingRevenueOutlet(arrayOfSeriesRevenueSharingRevenueOutletString)
        const arrayOfSeriesRevenueSharingServiceOutletString = data?.revenue_sharing?.map((obj) =>
          obj.service_share_outlet ? obj.service_share_outlet : 0
        )
        setArrayOfSeriesRevenueSharingServiceOutlet(arrayOfSeriesRevenueSharingServiceOutletString)
        const arrayOfSeriesRevenueSharingServicePartnerString = data?.revenue_sharing?.map((obj) =>
          obj.service_share_partner ? obj.service_share_partner : 0
        )
        setArrayOfSeriesRevenueSharingServicePartner(
          arrayOfSeriesRevenueSharingServicePartnerString
        )

        const arrayOfSeriesRevenueSharingRevenuePartnerString = data?.revenue_sharing?.map(
          (obj, index) => {
            return obj.revenue_share_partner ? obj.revenue_share_partner : 0
          }
        )
        setArrayOfSeriesRevenueSharingRevenuePartner(
          arrayOfSeriesRevenueSharingRevenuePartnerString
        )

        //WIB
        setArrayOfSeriesWibServiceOutlet([])
        setArrayOfSeriesWibServicePartner([])
        setArrayOfLabelsWib([])
        if (data?.revenue_sharing_wib) {
          const arrayOfLabelsWibString = data?.revenue_sharing_wib?.map((obj) =>
            obj.periode ? obj.periode : ''
          )
          setArrayOfLabelsWib(arrayOfLabelsWibString)
          const arrayOfSeriesWibServiceOutletString = data?.revenue_sharing_wib?.map((obj) =>
            obj.service_share_outlet ? obj.service_share_outlet : 0
          )
          setArrayOfSeriesWibServiceOutlet(arrayOfSeriesWibServiceOutletString)
          const arrayOfSeriesWibServicePartnerString = data?.revenue_sharing_wib?.map((obj) =>
            obj.service_share_partner ? obj.service_share_partner : 0
          )
          setArrayOfSeriesWibServicePartner(arrayOfSeriesWibServicePartnerString)
        }

        //WITA
        setArrayOfSeriesWitaServiceOutlet([])
        setArrayOfSeriesWitaServicePartner([])
        setArrayOfLabelsWita([])
        if (data?.revenue_sharing_wita) {
          const arrayOfLabelsWitaString = data?.revenue_sharing_wita?.map((obj) =>
            obj.periode ? obj.periode : ''
          )
          setArrayOfLabelsWita(arrayOfLabelsWitaString)
          const arrayOfSeriesWitaServiceOutletString = data?.revenue_sharing_wita?.map((obj) =>
            obj.service_share_outlet ? obj.service_share_outlet : 0
          )
          setArrayOfSeriesWitaServiceOutlet(arrayOfSeriesWitaServiceOutletString)
          const arrayOfSeriesWitaServicePartnerString = data?.revenue_sharing_wita?.map((obj) =>
            obj.service_share_partner ? obj.service_share_partner : 0
          )
          setArrayOfSeriesWitaServicePartner(arrayOfSeriesWitaServicePartnerString)
        }

        //WIT
        setArrayOfSeriesWitServiceOutlet([])
        setArrayOfSeriesWitServicePartner([])
        setArrayOfLabelsWit([])
        if (data?.revenue_sharing_wit) {
          const arrayOfLabelsWitString = data?.revenue_sharing_wit?.map((obj) =>
            obj.periode ? obj.periode : ''
          )
          setArrayOfLabelsWit(arrayOfLabelsWitString)
          const arrayOfSeriesWitServiceOutletString = data?.revenue_sharing_wit?.map((obj) =>
            obj.service_share_outlet ? obj.service_share_outlet : 0
          )
          setArrayOfSeriesWitServiceOutlet(arrayOfSeriesWitServiceOutletString)
          const arrayOfSeriesWitServicePartnerString = data?.revenue_sharing_wit?.map((obj) =>
            obj.service_share_partner ? obj.service_share_partner : 0
          )
          setArrayOfSeriesWitServicePartner(arrayOfSeriesWitServicePartnerString)
        }
      }
    }
  }, [data])

  return (
    <>
      <div className='mt-8'>
        <h4>Summary Information</h4>
        <div className='row'>
          <MemoizedRevenueSharingCard />
        </div>
        <div className='d-flex row mt-5'>
          <MemoizedRevenueSharingCardSharing />
        </div>
      </div>

      <div className='col-xl-12 g-5 g-xl-8 pt-10'>
        <MemoizeRevenueSharingHistory />
        <MemoizeRevenueWib />
        <MemoizeRevenueWita />
        <MemoizeRevenueWit />
      </div>

      <div className='row g-5 g-xl-8 pt-10'>
        <div className='col-xl-6'>
          <TopOutlets />
        </div>
        <div className='col-xl-6'>
          <TopCities />
        </div>
      </div>
    </>
  )
}

export default Overview
