import {useQuery} from 'react-query'
import {getTop5Products, getTop5Therapist, getTrendCategory, getTrendTreatment} from './_requests'

export const useGetTop5Product = (body: any) =>
  useQuery({
    queryFn: () => getTop5Products(body),
    queryKey: `top-5-product-${JSON.stringify(body)}`,
    refetchOnWindowFocus: false,
    enabled: Boolean(body),
  })

export const useGetTop5Therapist = (body: any) =>
  useQuery({
    queryFn: () => getTop5Therapist(body),
    queryKey: `top-5-therapist-${JSON.stringify(body)}`,
    refetchOnWindowFocus: false,
    enabled: Boolean(body),
  })

export const useGetTrendCategory = (body: any) =>
  useQuery({
    queryFn: () => getTrendCategory(body),
    queryKey: `trend-category-${JSON.stringify(body)}`,
    refetchOnWindowFocus: false,
    enabled: Boolean(body),
  })

export const useGetTrendTreatment = (body: any) =>
  useQuery({
    queryFn: () => getTrendTreatment(body),
    queryKey: `trend-treatment-${JSON.stringify(body)}`,
    refetchOnWindowFocus: false,
    enabled: Boolean(body),
  })
