import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'

export interface FilterTop {
  set_sub_brand_id?: boolean
  sub_brand_id?: string[]
  set_brand_id?: boolean
  brand_id?: string[]
  set_area?: boolean
  area?: string[]
  set_region_id?: boolean
  region_id?: string[]
  set_city_id?: boolean
  city_id?: string[]
  set_outlet_id?: boolean
  outlet_id?: string[]
  set_month?: boolean
  month?: string[]
  set_year?: boolean
  year?: string | null
}

export interface RevenueStreamOutlet {
  outlet_id?: string
  outlet_name?: string
  total_transaction?: number
  total_transaction_value?: number
}

export interface RevenueStreamCity {
  city_id?: string
  city_name?: string
  total_transaction?: number
  total_transaction_value?: number
}

export interface RevenueStreamArea {
  outlet_name?: string
  total_transaction?: number
  total_transaction_value?: number
}

export type RevenueStreamOutletQueryResponse = BaseResponse<Array<RevenueStreamOutlet>>
export type RevenueStreamCityQueryResponse = BaseResponse<Array<RevenueStreamCity>>
export type RevenueStreamAreaQueryResponse = BaseResponse<Array<RevenueStreamArea>>

export interface Revenue {
  month?: string
  total_transaction?: number
  total_sales?: number
}

export type Treatment = {
  month?: string
  total_qty?: number
}

export type SummaryTrend = {
  summary_trend?: SummaryTrendData
}

export type SummaryTrendData = {
  product?: number
  transaction?: number
  sales?: number
}

export type Top5Therapist = {
  total_qtys?: number
  outlet_guid?: string
  outlet_name?: string
  total_sales?: number
  employee_guid?: string
  employee_name?: string
  employee_picture?: string
}

const currentDate = new Date()
export const initialFilter: FilterTop = {
  set_sub_brand_id: false,
  sub_brand_id: [],
  set_brand_id: false,
  brand_id: [],
  set_area: false,
  area: [],
  set_region_id: false,
  region_id: [],
  set_city_id: false,
  city_id: [],
  set_outlet_id: false,
  outlet_id: [],
  set_year: true,
  year: currentDate.getFullYear().toString(),
  set_month: false,
  month: [],
}
