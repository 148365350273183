import {axios} from '@/_metronic/helpers/network/Axios'
import {AxiosResponse} from 'axios'
import {
  FilterReportTrendAnalysis,
  ReportTrendAnalysisQueryResponse,
  Top5Product,
  TopFiveTherapist,
  TrendCategory,
  TrendTreatment,
} from './_models'
import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'

const REPORT_API_URL = '/report-services'
const GET_REPORT_TREND_ANALYSIS_LIST = '/dashboard/trend-analysis'

const getReportTrendAnalysis = (
  body: FilterReportTrendAnalysis
): Promise<ReportTrendAnalysisQueryResponse> => {
  return axios
    .post(`${REPORT_API_URL}${GET_REPORT_TREND_ANALYSIS_LIST}`, body)
    .then((d: AxiosResponse<ReportTrendAnalysisQueryResponse>) => d.data)
}

const getTop5Products = (body: any) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_TREND_ANALYSIS_LIST}/top-5-product`)
    .then((d: AxiosResponse<BaseResponse<Top5Product[]>>) => d.data)

const getTop5Therapist = (body: any) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_TREND_ANALYSIS_LIST}/top-5-therapist`)
    .then((d: AxiosResponse<BaseResponse<TopFiveTherapist[]>>) => d.data)

const getTrendCategory = (body: any) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_TREND_ANALYSIS_LIST}/category`)
    .then((d: AxiosResponse<BaseResponse<TrendCategory[]>>) => d.data)

const getTrendTreatment = (body: any) =>
  axios
    .post(`${REPORT_API_URL}${GET_REPORT_TREND_ANALYSIS_LIST}/treatment`)
    .then((d: AxiosResponse<BaseResponse<TrendTreatment[]>>) => d.data)

export {
  getReportTrendAnalysis,
  getTop5Products,
  getTop5Therapist,
  getTrendCategory,
  getTrendTreatment,
}
