/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {FormattedNumber} from 'react-intl'
import IntlProvider from 'react-intl/src/components/provider'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {TopFiveTherapist} from '../core/_models'
import {useGetTop5Therapist} from '../core/_query'

type Props = {
  className: string
}

const TopEmployees: React.FC<Props> = ({className}) => {
  const {state} = useQueryRequest()
  const {data: topTreatmentData} = useGetTop5Therapist(state)

  const data = useMemo(() => {
    return topTreatmentData?.response?.data || []
  }, [topTreatmentData])

  const [showProfile, setShowProfile] = useState<boolean>(false)

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3'>Top 5 Therapist</span>
        </h3>
        {/* <div className='card-toolbar' data-kt-buttons='true'>
          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1'
            id='kt_charts_widget_2_year_btn'
          >
            Transaction
          </a>

          <a
            className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1'
            id='kt_charts_widget_2_month_btn'
          >
            Sales
          </a>
        </div> */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-30px'>No</th>
                <th className='min-w-150px'>Profile Picture</th>
                <th className='min-w-150px'>Name</th>
                <th className='min-w-150px'>Outlet</th>
                <th className='min-w-100px'>Quantity</th>
                <th className='min-w-150px'>Revenue</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data !== undefined ? (
                data?.map((therapist, index) => (
                  <tr key={index}>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {index + 1}
                      </a>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                          {therapist.employee_picture ? (
                            <div className='symbol-label'>
                              <img
                                src={therapist.employee_picture}
                                alt={'No Photo'}
                                className='w-100 cursor-pointer '
                                onClick={() => setShowProfile(true)}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <Modal
                        show={showProfile}
                        dialogClassName='modal-sm modal-dialog-centered'
                        onHide={() => setShowProfile(false)}
                      >
                        <div className='modal-content'>
                          <div className='modal-body'>
                            <img
                              src={therapist.employee_picture}
                              alt='no picture'
                              width='100%'
                              height='100%'
                            />
                          </div>
                        </div>
                      </Modal>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {therapist.employee_name}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                        {therapist.outlet_name}
                      </a>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-dark me-2 fs-7 fw-semibold'>
                            {therapist.total_qtys}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-dark me-2 fs-7 fw-semibold'>
                            Rp.{' '}
                            <IntlProvider locale='id'>
                              <FormattedNumber value={therapist.total_sales || 0} />
                            </IntlProvider>{' '}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TopEmployees}
