import {useQuery} from 'react-query'
import {
  getRevenueTrendAnalysis,
  getSummaryTrend,
  getTop5Therapist,
  getTreatmentTrendAnalysis,
} from './_requests'

export const useGetRevenue = (body: any) =>
  useQuery({
    queryKey: `dashboard-revenue-${JSON.stringify(body)}`,
    queryFn: () => getRevenueTrendAnalysis(body),
    enabled: Boolean(body),
    refetchOnWindowFocus: false,
  })

export const useGetTreatment = (body: any) =>
  useQuery({
    queryKey: `dashboard-treatment-${JSON.stringify(body)}`,
    queryFn: () => getTreatmentTrendAnalysis(body),
    enabled: Boolean(body),
    refetchOnWindowFocus: false,
  })

export const useGetSummaryTrend = (body: any) =>
  useQuery({
    queryKey: `summary-trend-${JSON.stringify(body)}`,
    queryFn: () => getSummaryTrend(body),
    enabled: Boolean(body),
    refetchOnWindowFocus: false,
  })

export const useGetTop5Therapist = (body: any) =>
  useQuery({
    queryKey: `top-5-therapist-${JSON.stringify(body)}`,
    queryFn: () => getTop5Therapist(body),
    enabled: Boolean(body),
    refetchOnWindowFocus: false,
  })
