import {useMemo} from 'react'
import {InventoryAsset} from '../../apps/dashboard/components/InventoryAsset'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useGetRevenueSharingCardSharing} from '../core/_query'

function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
}

const RevenueSharingCardSharing = () => {
  const {state} = useQueryRequest()
  const {data: cardData} = useGetRevenueSharingCardSharing(state)

  const data = useMemo(() => {
    return cardData?.response?.data
  }, [cardData])

  return (
    <>
      <div className='col'>
        <InventoryAsset
          className='card-xl mb-xl-8'
          // className='card-xl-stretch mb-xl-8'
          svgIcon='chart-simple'
          color='white'
          iconColor='primary'
          title={
            data?.revenue_share_outlet
              ? 'Rp ' + numberWithCommas(data?.revenue_share_outlet)
              : 'Rp. 0'
          }
          description='Revenue Sharing to Outlet'
        />
      </div>
      <div className='col'>
        <InventoryAsset
          className='card-xl mb-xl-8'
          // className='card-xl-stretch mb-xl-8'
          svgIcon='bank'
          color='white'
          iconColor='primary'
          title={
            data?.revenue_share_partner
              ? 'Rp ' + numberWithCommas(data?.revenue_share_partner)
              : 'Rp. 0'
          }
          description='Revenue Sharing to Hotel'
        />
      </div>
      <div className='col'>
        <InventoryAsset
          className='card-xl mb-xl-8'
          // className='card-xl-stretch mb-xl-8'
          svgIcon='briefcase'
          color='white'
          iconColor='primary'
          title={
            data?.service_share_outlet
              ? 'Rp ' + numberWithCommas(data?.service_share_outlet)
              : 'Rp. 0'
          }
          description='Service Charge to Outlet'
        />
      </div>
      <div className='col'>
        <InventoryAsset
          className='card-xl mb-xl-8'
          // className='card-xl-stretch mb-xl-8'
          svgIcon='chart-pie-simple'
          color='white'
          iconColor='primary'
          title={
            data?.service_share_partner
              ? 'Rp ' + numberWithCommas(data?.service_share_partner)
              : 'Rp. 0'
          }
          description='Service Charge to Hotel'
        />
      </div>
    </>
  )
}

export {RevenueSharingCardSharing}
