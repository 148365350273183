import {BarChart} from '@/_metronic/partials/content/chart/BarChart'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useGetRevenueServiceSharingHistory} from '../core/_query'
import {useMemo} from 'react'

const RevenueServiceSharingHistory = () => {
  const {state} = useQueryRequest()
  const {data, isLoading} = useGetRevenueServiceSharingHistory(state)

  const arrData = useMemo(() => {
    return data?.response?.data || []
  }, [data])

  const labels = useMemo(() => {
    return arrData?.map((x) => x?.periode || '')
  }, [arrData])

  // revenue
  const revenueOutlet = useMemo(() => {
    return arrData?.map((x) => x?.revenue_share_outlet || 0)
  }, [arrData])

  const revenuePartner = useMemo(() => {
    return arrData?.map((x) => x?.revenue_share_partner || 0)
  }, [arrData])

  // service
  const serviceOutlet = useMemo(() => {
    return arrData?.map((x) => x?.service_share_outlet || 0)
  }, [arrData])

  const servicePartner = useMemo(() => {
    return arrData?.map((x) => x?.service_share_partner || 0)
  }, [arrData])

  return (
    <>
      <BarChart
        className='area-wib shadow-wit'
        color='success'
        title='Revenue Sharing History'
        subtitle=''
        height='400px'
        series={[
          {
            name: 'Outlet',
            data: revenueOutlet,
          },
          {
            name: 'Hotel',
            data: revenuePartner,
          },
        ]}
        categories={labels}
        total={0}
        isTotal={false}
        data={arrData?.map((x) => ({
          month: x.periode,
          revenue_share_outlet: x.revenue_share_outlet,
          revenue_share_partner: x.revenue_share_partner,
        }))}
        headers={['Periode', 'Revenue Sharing Outlet', 'Revenue Sharing Partner']}
        formatCurrency={['revenue_share_outlet', 'revenue_share_partner']}
        isLoading={isLoading}
      />
      <BarChart
        className='mt-10 area-wib shadow-wit'
        color='primary'
        title='Service Sharing History'
        subtitle=''
        height='400px'
        series={[
          {
            name: 'Outlet',
            data: serviceOutlet,
          },
          {
            name: 'Hotel',
            data: servicePartner,
          },
        ]}
        categories={labels}
        total={0}
        isTotal={false}
        data={arrData?.map((x) => ({
          month: x.periode,
          service_share_outlet: x.service_share_outlet,
          service_share_partner: x.service_share_partner,
        }))}
        headers={['Periode', 'Service Sharing Outlet', 'Service Sharing Partner']}
        formatCurrency={['service_share_outlet', 'service_share_partner']}
        isLoading={isLoading}
      />
    </>
  )
}

export {RevenueServiceSharingHistory}
