import {BarChart} from '@/_metronic/partials/content/chart/BarChart'
import {useGetRevenue, useGetSummaryTrend} from '../core/_query'
import {useQueryRequest} from '@/app/modules/report-trend-analysis/core/QueryRequestProvider'
import {memo, useMemo} from 'react'
import {FormattedNumber} from 'react-intl'
import {BarChartOnly} from '@/_metronic/partials/content/chart/BarChartOnly'

const MemoizedBarChart = memo(BarChartOnly)

const RevenueBar = () => {
  const {state} = useQueryRequest()
  const {data: revenueData, isLoading} = useGetRevenue(state)

  const revenue = useMemo(() => {
    return revenueData?.response?.data
  }, [revenueData])

  return (
    <>
      <div className='card area-wib shadow-wit'>
        <RevenueHeader />
        <MemoizedBarChart
          className='area-wib shadow-wit'
          color='primary'
          title='Revenue'
          data={revenue}
          headers={['Bulan', 'Total Transaksi', 'Total Sales']}
          formatCurrency={['total_sales']}
          series={[
            {
              name: 'Revenue',
              data: revenue?.map((item) => item.total_sales),
            },
          ]}
          categories={revenue?.map((item) =>
            item.month
              ?.split(`${state.year || new Date().getFullYear()}`)[0]
              .substring(0, 3)
              .trim()
          )}
          //   total={data?.summary_trend?.sales}
          isLoading={isLoading}
        />
      </div>
    </>
  )
}

const RevenueHeader = () => {
  const {state} = useQueryRequest()
  const {data: summaryTrendData, isLoading: summaryTrendLoading} = useGetSummaryTrend(state)

  const summaryTrend = useMemo(() => {
    return summaryTrendData?.response?.data
  }, [summaryTrendData])

  return (
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>Revenue</span>
      </h3>
      <div className='card-toolbar'>
        {summaryTrendLoading ? (
          <div className='h-25px w-70px placeholder-glow'>
            <span className='w-100 h-100 placeholder' />
          </div>
        ) : (
          <span className={`card-title fw-bold fs-1 text-primary`}>
            Rp. <FormattedNumber value={summaryTrend?.summary_trend?.sales || 0} />
          </span>
        )}
      </div>
    </div>
  )
}

export {RevenueBar}
